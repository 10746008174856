import { z } from "zod";
import type React from "react";

export interface CountryIsSchema {
  ip: string;
  country: string;
}

export const publicNumbers = z.object({
  bulletPointCount: z.number(),
  questionAnswerCount: z.number(),
  satisfiedUserCount: z.number(),
});

export type PublicNumbers = z.infer<typeof publicNumbers>;

export interface CostCalculation {
  quinoCost: number;
  basicCost: number;
  costDifference: number;
}
export const baseResponse = z.object({
  response: z.string(),
});
export type BaseResponse = z.infer<typeof baseResponse>;

export const trialFileUpload = z.object({
  message: z.string(),
  temp_document_id: z.string(),
});
export type TrialFileUpload = z.infer<typeof trialFileUpload>;

export const trialRequest = z.object({
  inputText: z.string(),
});
export type TrialRequest = z.infer<typeof trialRequest>;
export const trialQARequest = z.object({
  question: z.string(),
  documentName: z.union([z.literal("book1"), z.literal("book2"), z.literal("book3")]),
});
export type TrialQARequest = z.infer<typeof trialQARequest>;

export const costCalculationRequest = z.object({
  callsPerMonth: z.number(),
  gbOfData: z.number(),
});
export type CostCalculationRequest = z.infer<typeof costCalculationRequest>;

export const contactInfo = z.object({
  emailAddress: z.string(),
  company: z.string(),
  titleInTheCompany: z.string(),
  contactPerson: z.string(),
});

export type ContactInfo = z.infer<typeof contactInfo>;

export interface SubscriptionPlanColumnProps {
  themeKey?: "white" | "blue" | "purple";
  planTitle?: string;
  price?: number;
  description?: string;
  featureEntries?: string[];
}

export interface ReworkedSubscriptionPlanColumnProps extends SubscriptionPlanColumnProps {
  icon?: React.ReactNode;
  isMonthly?: boolean;
  highlighted?: boolean;
  discount?: number; // value between 0-1
  buttonText?: string;
  detailedDescription?: string;
}

export interface PlanComparisonCategory {
  id: string;
  access?: string | { text: string; className?: string };
  features?: Array<string | number | { text: string | number; className?: string; isValid?: boolean }>;
}

export interface PlanComparisonEntity {
  id: string;
  name: string;
  price: number;
  basicFunctions: PlanComparisonCategory;
  performanceMetrics: PlanComparisonCategory;
  aiFunctions: PlanComparisonCategory;
  communityFeatures: PlanComparisonCategory;
  highlighted?: boolean;
}
export interface ReviewCardType {
  id: string;
  stars: number;
  title: string;
  description: string;
  avatarUrl: string;
  avatarTitle: string;
  avatarDescription: string;
}

export enum ModalKeys {
  COOKIE_CONSENT_MODAL = "COOKIE_CONSENT_MODAL",
}

export interface ModalStateType {
  [ModalKeys.COOKIE_CONSENT_MODAL]: boolean;
}

export interface ModalSlice {
  modals: ModalStateType;

  setModalKey: <T extends keyof ModalStateType>(key: T, value: ModalStateType[T]) => void;
}

export interface BlogChapterStateSlice {
  chapter: string;
  setChapter: (value: string) => void;
}

export type AppSlice = ModalSlice & BlogChapterStateSlice;

export const cleanStripePlansSchema = z.object({
  name: z.string(),
  subtitle: z.string(),
  priceId: z.string().nullish(),
  pruductId: z.string().nullish(),
  monthlyPrice: z.number(),
  firebaseRole: z.string(),
  type: z.enum(["monthly", "one-time", "yearly"]),
  features: z.array(z.object({ featureDescription: z.string(), included: z.boolean() })),
});

export const cleanStripePlansResponse = z.array(cleanStripePlansSchema);
export type CleanStripePlansResponse = z.infer<typeof cleanStripePlansResponse>;

export type StripePlanPeriod = "monthly" | "yearly";

export interface PlanDescription {
  title: string;
  subTitle: string;
  currency?: string;
  description: string;
  name: string;
  priceId: string | null | undefined;
  product?: string;
  firebaseRole: string;
  price: number | string;
  monthlyPrice: number | string;
  highlighted?: boolean;
  showArrow?: boolean;
}


export interface StrapiEntity {
  id: number;
  attributes: unknown;
  meta: unknown;
}

export interface StrapiResponse {
  meta: unknown;
  error?: unknown;
}

export interface StrapiSingleResponse<T extends StrapiEntity> extends StrapiResponse {
  data: T;
}

export interface StrapiMultiResponse<T extends StrapiEntity> extends StrapiResponse {
  data: T[];
  meta: {
    pagination?: {
      page: number;
      pageSize: number;
      pageCount: number;
      total: number;
    };
  };
}

export interface StrapiMedia extends StrapiEntity {
  attributes: {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: string;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: unknown;
    createdAt: string;
    updatedAt: string;
  };
}

export interface StrapiTag extends StrapiEntity {
  attributes: {
    value: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface StrapiArticle extends StrapiEntity {
  attributes: {
    author: string;
    title: string;
    description: string;
    views: string;
    createdAt: string;
    content: string;
    updatedAt: string;
    publishedAt: string;
    slug: string;
    category?: { data?: StrapiCategory };
    banner?: { data: StrapiMedia };
    authorPicture?: { data: StrapiMedia };
    tags?: {
      data: StrapiTag[];
    };
    suggestions?: { data: Omit<StrapiArticle, "suggestions">[] };
  };
}
export interface StrapiFeature extends StrapiEntity {
  attributes: {
    title: string;
    description: string;
    footerText: string;
    uid: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    image?: { data: StrapiMedia };
  };
}
export interface StrapiLegalInfo extends StrapiEntity {
  attributes: {
    termsAndConditions: string;
    privacyPolicy: string;
    cookiePolicy: string;
  };
}
export interface StrapiUseCase extends StrapiEntity {
  attributes: {
    uid: string;
    title: string;
    captionTitle: string;
    description: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    videoUrl: { data: StrapiMedia };
    icon: { data: StrapiMedia };
  };
}
export interface StrapiHighlight extends StrapiEntity {
  attributes: {
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    highlights: {
      data: StrapiArticle[];
    };
    categories: {
      data: StrapiCategory[];
    };
  };
}

export interface ParagraphSummary {
  id: number;
  title: string;
  description: string;
  pages: string;
}

export interface StrapiLanguage extends StrapiEntity {
  attributes: {
    value: string;
    label: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface StrapiCategory extends StrapiEntity {
  attributes: {
    value: string;
    label: string;
    categoryGroup: StrapiCategoryGroup;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface StrapiCategoryGroup extends StrapiEntity {
  attributes: {
    value: string;
    label: string;
    categories: { data: StrapiCategory[] };
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}
export interface QuestionReference {
  id: number;
  page: number;
  text: string;
}
export interface StrapiBookQuestion extends StrapiEntity {
  attributes: {
    slug: string;
    seoTitle?: string;
    seoDescription?: string;
    text: string;
    answer: {
      id: number;
      author: string;
      text: string;
    };
    book: { data: StrapiBook };
    relatedQuestions: { data: StrapiBookQuestion[] };
    references: QuestionReference[];
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface StrapiBook extends StrapiEntity {
  attributes: {
    isbn: string;
    slug: string;
    metadata: {
      id: number;
      hasDetailedMetadata: string;
      title: string;
      subtitle: string;
      description: string;
      authors: string[];
      publisher: string;
      publisherDate: string;
      printedPageNumber: number;
      maturityRating: string;
      thumbnailUrl: string;
      otherEditionIsbns: string[];
      bookLink: string;
      language: { data: StrapiLanguage };
      categories: { data: StrapiCategory[] };
      amazonLink: string;
    };
    paragraphSummaries: ParagraphSummary[];
    bookQuestions: { data: StrapiBookQuestion[] };
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export const projectDocumentRedisTickerSchema = z
  .object({
    projectId: z.string(),
    documentId: z.string(),
    documentDownloaded: z.boolean(),
    documentHealthcheckDone: z.boolean(),
    textractionDone: z.boolean(),
    documentMetadataGatheringDone: z.boolean(),
    textEmbeddingDone: z.boolean(),
    paragraphAggregationDone: z.boolean(),
    dbWritingDone: z.boolean(),
    errorMessage: z.string().nullish(),
    errorMessageDuringDocumentProcessing: z.string().nullish(),
    numOfSummarizedParagraphs: z.number(),
    totalNumOfParagraphs: z.number(),
  })
  .strict();

export type ProjectDocumentRedisTicker = z.infer<typeof projectDocumentRedisTickerSchema>;
